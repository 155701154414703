<template>
    <div class="details_content un_select">
        <div class="code_img">
            <div class="code_img_code">
                <img :src="code_url" v-if="code_url" class="selectNone" alt="二维码" />
                <div v-if="!code_url">--</div>
            </div>

            <div class="code_save">{{ isInMiniprogram ? '长按分享二维码' : '长按识别二维码，或保存图片' }}</div>
        </div>

        <div v-if="showState">
            <div class="details_user">
                <div class="details_title">
                    <div>群聊</div>
                    <div class="details_number">共{{ use_customerLength }}个</div>
                </div>
                <div class="details_list">
                    <div class="list_item" v-for="(group_item, group_index) in group_customerMap" :key="group_index">
                        <img :src="group_item.group_code_img" class="selectNone" alt="二维码" />
                        <div class="item_content">
                            <div class="content_title">
                                <div class="title">
                                    {{ group_item.qr_code_name }}
                                </div>
                                <div
                                    class="content_codeState"
                                    :class="checkExpire(group_item.qr_code_validity) ? 'overdueCurrent' : 'useCurrent'"
                                >
                                    {{ checkExpire(group_item.qr_code_validity) ? '已过期' : '使用中' }}
                                </div>
                            </div>
                            <div class="content_date">失效时间：{{ group_item.qr_code_validity }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="details_user" v-if="use_customerMap[0].name || use_customerMap[0].NAME">
                <div class="details_title">
                    <div>使用员工</div>
                    <div class="details_number">共{{ use_customerLength }}人</div>
                </div>
                <div class="details_list">
                    <div class="list_item user_list" v-for="(item, index) in use_customerMap" :key="index">
                        <div class="item_avatar">
                            <img v-default-avatar :src="item.avatar" class="selectNone" alt="头像" />
                        </div>
                        <div class="item_name">
                            {{ item.name || item.NAME }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AVATAR_DEFAULT_IMG } from '@/constant';
import { mapState } from 'vuex';

export default {
    name: 'codeDetails',
    data() {
        return {
            AVATAR_DEFAULT_IMG,
            code_url: '',
            use_customerMap: [],
            use_customerList: [],
            use_customerLength: 0,
            group_card_id: '',
            showState: false,
            isChannel: false,
            group_customerMap: {},
            userState: ''
        };
    },

    computed: {
        ...mapState(['isInMiniprogram'])
    },

    created() {
        document.title = this.$route.query.name;
        if (this.$route.query.type == 'channel') {
            this.code_url = this.$route.query.code_url;
            this.use_customerMap = JSON.parse(this.$route.query.use_customer);
            this.isChannel = true;
            this.showState = false;
        } else {
            this.group_card_id = this.$route.query.id;
            this.code_url = this.$route.query.code_url;
            this.isChannel = false;
            this.showState = true;
        }
    },
    mounted() {
        this.$toast.loading({
            duration: 0,
            message: '加载中...',
            forbidClick: true
        });
        if (this.isChannel) {
            this.use_customerLength = this.use_customerMap.length || 0;
            this.$toast.clear();
        } else {
            //群组活码发起请求
            this.getData();
        }
    },
    methods: {
        getData() {
            this.$ajax('group_qr_code_list', {
                group_card_id: `${this.group_card_id}`,
                page: 1,
                page_size: 10,
                qr_name: ''
            }).then((res) => {
                this.$toast.clear();
                if (res.data && res.data.code === 0) {
                    this.group_customerMap = res.data.data.list;
                    this.use_customerLength = this.group_customerMap.length || 0;
                } else {
                    this.$toast('网络错误');
                }
            });
        },
        // 判断二维码是否过期
        checkExpire(qr_code_validity) {
            var timeEnd = new Date(Date.parse(qr_code_validity)),
                currentdate = new Date();
            return timeEnd < currentdate;
        }
    }
};
</script>
<style lang="scss" scoped>
.details_content {
    padding: 0.32rem;
    .selectNone {
        user-select: none;
        -webkit-touch-callout: none;
    }
    .code_img {
        border-radius: 0.16rem;
        background: #ffffff;
        border-radius: 0.16rem;
        padding: 0.96rem 1.36rem 0.32rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
            width: 100%;
            border-radius: 0.12rem;
        }
    }
    .code_save {
        margin-top: 0.72rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: #666666;
        line-height: 0.4rem;
    }
    .details_user {
        background: #ffffff;
        border-radius: 0.16rem;
        margin-top: 0.32rem;
        padding: 0 0.32rem;
        .details_title {
            height: 1.04rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.32rem;
            font-weight: 500;
            color: #333333;
            line-height: 0.48rem;
        }
        .details_number {
            font-weight: 400;
            color: #666666;
        }
        .list_item {
            height: 1.44rem;
            background: #ffffff;
            border-bottom: 0.02rem solid rgba(0, 0, 0, 0.08);
            display: flex;
            align-items: center;
            &:last-child {
                border: none;
            }
            img {
                width: 1.04rem;
                height: 1.04rem;
                border-radius: 0.12rem;
            }
        }
        .user_list {
            height: 0.96rem;
        }
        .item_content {
            margin-left: 0.24rem;
            display: flex;
            flex-direction: column;
        }
        .content_title {
            .title {
                font-size: 0.28rem;
                font-weight: 500;
                color: #333333;
                line-height: 0.4rem;
                max-width: 3.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            display: flex;
        }
        .content_codeState {
            margin-left: 0.16rem;
            border-radius: 0.16rem;
            font-size: 0.2rem;
            font-weight: 400;
            line-height: 0.2rem;
            display: flex;
            align-items: center;
            padding: 0.08rem;
        }
        .useCurrent {
            background-color: #ebf1ff;
            color: #1472ff;
        }
        .overdueCurrent {
            background: #f5f5f5;
            color: #b2b2b2;
        }
        .content_date {
            margin-top: 0.08rem;
            font-size: 0.24rem;
            font-weight: 400;
            color: #999999;
            line-height: 0.36rem;
        }
        .item_avatar {
            text-align: center;
            img {
                width: 0.64rem;
                height: 0.64rem;
                border-radius: 50%;
            }
        }
        .item_name {
            margin-left: 0.16rem;
            font-size: 0.28rem;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
